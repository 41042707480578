@media screen and (max-width: 767px) {
  .ps-sidebar-root {
    z-index: 9999 !important;
  }
}

.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.sidebar img {
  width: 100px;
}

.sidebar svg {
  width: 24px;
  height: 24px;
}

.ps-menu-button {
  margin-bottom: 10px;
  border-radius: 8px !important;
}

.ps-menu-button.ps-active {
  background-color: var(--primary);
  color: var(--foreground) !important;
}

.ps-menu-button.ps-active:hover {
  background-color: var(--primary) !important;
}

.ps-submenu-expand-icon .css-honxw6 {
  width: 10px;
  height: 10px;
}

.ps-submenu-expand-icon .css-jn69v9 {
  width: 10px;
  height: 10px;
}