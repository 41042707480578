@import url('./Colours.css');

.App {
  color: var(--black);
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--background);
}

.page-wrapper {
  margin: 10px;
  border-radius: 8px;
  background-color: white;
  padding: 60px;
  text-align: left;
  overflow-y: scroll;
  height: 98%;
}

@media screen and (max-width: 768px) {
  .page-wrapper {
    padding: 20px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

Link {
  text-decoration: none;
}

Link:hover {
  text-decoration: none;
}

.justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.only-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .only-mobile {
    display: block;
  }
}

.cursor-pointer {
  cursor: pointer;
}