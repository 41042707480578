.profile-pic-upload {
  border-radius: 50%;
  cursor: pointer;
  width: 150px;
}

.file-upload-container {
  border: 1px dashed var(--primary);
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.file-upload-container input {
  display: none;
}

.preview {
  border: 1px solid #1cd189;
  border-radius: 10px;
  padding: 5px 10px;
  list-style: none;
}