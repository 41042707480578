:root {
  --primary: #204392;
  --primary-100: #e9ecf4;
  --primary-200: #DEE2EE;
  --black: #333232;
  --red: #EF6363;
  --red-200: #EA3333;
  --yellow: #FFC300;
  --green: #00AF54;
  --background: #EDEEF1;
  --foreground: white;
  --white: white
}