.member-card-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
}

.member-card {
  border-radius: 8px;
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
}