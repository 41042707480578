.modal-content {
  border-radius: 16px;
  padding: 1rem;
  background-color: white;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}