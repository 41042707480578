.login {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  padding: 40px;
  border-radius: 16px;
  max-width: 400px;
  color: var(--primary);
  text-align: center;
  background: var(--white);
}