.primary-btn {
  background-color: var(--primary) !important;
}

.delete-btn {
  background-color: var(--red) !important;
}

.secondary-btn {
  border-color: var(--primary-100) !important;
  color: var(--primary) !important;
}

.MuiOutlinedInput-root {
  color: black !important;
  background-color: var(--white) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-100) !important;
}

.MuiInputBase-inputTypeSearch {
  cursor: pointer;
}

.checkbox {
  color: transparent;
  border-radius: 4px;
  background-color: var(--foreground);
  border: 1px solid var(--primary-100);
}

.checkbox-check {
  border: 1px solid var(--primary-100);
  border-radius: 4px;
  color: var(--primary);
}

.MuiBadge-badge {
  background-color: var(--red) !important;
}

.tab {
  background: transparent !important;
  color: var(--primary) !important;
  border-radius: 0px !important;
}

.active-tab {
  background: transparent !important;
  color: var(--primary) !important;
  border-radius: 0px !important;
  border-bottom: 1px solid !important;
}

.MuiStepIcon-text {
  fill: transparent !important;
}