.page-wrapper-transparent {
  margin: 10px;
  border-radius: 8px;
  padding: 60px;
  text-align: left;
  height: 90%;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .page-wrapper-transparent {
    padding: 20px;
  }
}

.dashboard-grid {
  display: grid;
  grid-gap: 2rem;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 600px) {
  .dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .dashboard-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.dashboard-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}

.icon-box {
  padding: 8px;
  border-radius: 4px;
  color: white;
  width: max-content;
  background-color: var(--green);
}